import { useForm } from "antd/lib/form/Form"
import { Form, Input, DatePicker, Button, notification, Row, Col, Card, Select} from "antd"
import locale from 'antd/lib/date-picker/locale/ru_RU'
import { store } from "../store/store";
import moment from "moment";
import { updateAnketa } from "../api/api";

const confirmAnketaUpdate = (props, form) => {    
    form.validateFields().then(
        result=>{
            updateAnketa(result)
            .then(
                response=>{
                    if(response === "ok"){
                        notification.open({
                            message: 'Ваш профиль обновлён',     
                        });
                        store.dispatch({type: 'UPDATE', payload: result})
                    }
                    else {
                        notification.error({
                            message: 'Ошибка',
                            description: result,        
                        });
                    }                    
                }
            )
        },
        error=>{
            notification.error({
                message: 'Ошибка заполнения формы'     
            });
        }
    )
}

export function Profile(props){
    let state = store.getState()
    const formInit = {
        ...state, 
        BirthDate: moment(state.BirthDate), 
        DocumentGiveDate: moment(state.DocumentGiveDate)
    }

    const validateMessages = {
        required: "Заполните это поле",
        types: {
            email: "Некорректный адрес",
        },        
    };
    const dateFormat = "DD/MM/yyyy"

    const [form] = useForm()
    return(
        <Card style={{height: "100%"}}>
            <Form form={form} size="large" labelCol={{span: 8}} wrapperCol={{span: 16}} initialValues={formInit} validateMessages={validateMessages}>
                <Row>
                    <Col xs={24} md={12}>
                        <Form.Item label="Фамилия" name="LastName" rules={[{required: true}]}>
                        <Input placeholder="Петров"/>
                        </Form.Item>
                        <Form.Item label="Имя" name="FirstName" rules={[{required: true}]}>
                            <Input placeholder="Петр"/>
                        </Form.Item>
                        <Form.Item label="Отчество" name="FatherName" rules={[{required: true}]}>
                            <Input placeholder="Петрович"/>
                        </Form.Item>
                        <Form.Item label="Телефон" name="Phone" rules={[{required: true, pattern: "([0-9]){10}"}]}>
                            <Input placeholder="9991112233"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item label="Серия" name="DocumentSeria" rules={[]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Номер" name="DocumentNumber" rules={[]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Кем выдан" name="DocumentUnit" rules={[]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Дата выдачи" name="DocumentGiveDate" rules={[{required: true}]}>
                            <DatePicker locale={locale} format={dateFormat}/>
                        </Form.Item>
                        <Form.Item label="Код подразделения" name="DocumentUnitCode" rules={[]}>
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Дата рождения" name="BirthDate" rules={[{required: true}]}>
                            <DatePicker locale={locale} format={dateFormat}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} md={12}>
                        <Form.Item label="Учебное заведение" name="School" rules={[{required: true}]}>
                            <Input placeholder="Школа №1"/>
                        </Form.Item>
                        <Form.Item label="Населенный пункт" name="SchoolPlace" rules={[{required: true}]}>
                            <Input placeholder="Нижний Новгород"/>
                        </Form.Item>
                        <Form.Item label="Класс" name="SchoolClass" rules={[{required: true}]}>
                            <Select>
                                {[11, 10, 9, 8, 7, 6].map(item=>(
                                    <Select.Option value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item wrapperCol={{span: 24}}>
                    <Button type='primary' htmlType="submit" style={{float: "right"}} onClick={() => confirmAnketaUpdate(props, form)}>Сохранить</Button>
                </Form.Item>                
            </Form>
            </Card>
    )
}