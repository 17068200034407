import { createStore } from 'redux'

let initState = {
    auth: false,
}

function reducer(state, action) {
    switch(action.type) {
        case "LOGIN" : return {...state, ...action.payload, auth: true,}
        case "LOGOUT": return {...state, auth: false}
        case "UPDATE": return {...state, ...action.payload}
        default      : return state
    }
}

export const store = createStore(reducer, initState)