import { Layout, Menu } from "antd";
import { useState, useEffect } from "react";
import { dispatchLogout, ping } from "../api/api";
import { Olymp } from "./Olymp";
import { Profile } from "./Profile";
import {
    TrophyOutlined,
    IdcardOutlined,
    LogoutOutlined,
    BarsOutlined
  } from '@ant-design/icons';

import logo from "../logo.png"
import { Result } from "./Result";

const { Header, Content, Footer, Sider } = Layout;

export function Main(){
    const [page, setPage] = useState('olymp')
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = (width <= 768);
    return (
        <Layout style={{minHeight: '100%'}}>
            <Sider theme="light" collapsed={isMobile}>
                <div style={{margin: "1rem", marginLeft:"0.5rem"}}>
                    <img src={logo} width={64}/>
                </div>
                <Menu mode="vertical" selectedKeys={1} style={{fontWeight: "100"}} theme="light">
                    <Menu.Item onClick={()=>setPage('olymp')} icon={<TrophyOutlined />} style={page === "olymp"? {color: "#52c41a"}: {}}>
                        Мероприятия
                    </Menu.Item>
                    <Menu.Item onClick={()=>setPage('result')} icon={<BarsOutlined />} style={page === "result"? {color: "#52c41a"}: {}}>
                        Ваши результаты
                    </Menu.Item>
                    <Menu.Item onClick={()=>setPage('profile')} icon={<IdcardOutlined />} style={page === "profile"? {color: "#52c41a"}: {}}>
                        Профиль
                    </Menu.Item>
                    <Menu.Item onClick={()=>dispatchLogout()} icon={<LogoutOutlined />}>
                        Выход
                    </Menu.Item>                        
                </Menu>  
            </Sider>
            <Layout >
                <Content>
                    {page === 'olymp' &&
                        <Olymp/>                    
                    }
                    {page ==='profile' &&
                        <Profile/>                  
                    }
                    {page ==='result' &&
                        <Result/>                  
                    }
                </Content>
            </Layout>
        </Layout>
    )
}