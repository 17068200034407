import { notification } from "antd"
import { store } from "../store/store"

const sURL = process.env.REACT_APP_SERVER_URL

const postJSON = (data) => {
    return {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(data)
    }
}

export const dispatchLogin = (data) => {
    let handlerURL = '/login'
    if (typeof data.token !== 'undefined'){
        handlerURL = '/logtoken'
    }
    fetch(sURL + handlerURL, postJSON(data))
    .then(response=>response.json())
    .then(result => {
        if(result.Token) {
            store.dispatch({type: 'LOGIN', payload: result})
            setToken(result.Token)
        }
        else{
            notification.error({
                message: 'Неверный логин или пароль',       
            });
        }
    }) 
}

export const dispatchLogout = () => {
    deleteToken()
    store.dispatch({type: 'LOGOUT'})
}

export const register = async (data) => {
    const response = await fetch(sURL + '/register', postJSON({
        ...data,
        birthDate: data.birthDate.format("YYYY-MM-DD"),
        documentGiveDate: data.documentGiveDate.format("YYYY-MM-DD")
    }))
    return await response.json()    
}

export async function getDocTypes() {
    const resp = await fetch(sURL + '/getDocumentTypes')
    return resp.json()
}

export function setToken(token){
    document.cookie = "token="+token+"; SameSite=Lax"
}

export function getCookie(name) {
    console.log(name)
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    console.log(name, matches)
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

export function getToken(){
    return getCookie("token")
}

export function deleteToken(){
    document.cookie = "token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
}

export const updateAnketa = async (data) => {
    const response = await fetch(sURL + '/updateAnketa', postJSON({
        ...store.getState(),
        ...data,
        birthDate: data.BirthDate.format("YYYY-MM-DD"),
        documentGiveDate: data.DocumentGiveDate.format("YYYY-MM-DD")
    }))
    return await response.json()  
}

export const getOlymp = async (data) => {
    const response = await fetch(sURL + '/getOlymp', postJSON({
        'Token': store.getState().Token
    }))
    return await response.json()  
}

export const olympReg = async(id) => {
    const response = await fetch(sURL + '/olympReg', postJSON({
        'Token': store.getState().Token,
        'OlympId': id
    }))
    return await response.json() 
}

export const genPDF = async(olympId) => {
    const response = await fetch(sURL + '/genPDF', postJSON({
        'Token': store.getState().Token,
        'OlympId': olympId
    }))
    return await response.blob()
}

export const getResults = async() => {
    const response = await fetch (sURL + '/results', postJSON({
        'Token': store.getState().Token
    }))
    return await response.json()
}

export const ping = async() => {
    const response = await fetch(sURL + '/ping')
    return await response.text()
}