
import { useEffect, useState } from "react"
import { getResults } from "../api/api"
import {Card, Row, Col, Divider} from "antd"

export function Result(){
    let [results, setResults] = useState([])
    useEffect(()=>getResults().then(res=>{setResults(res)}), [])
    return(
        <Card style={{minHeight: "100%"}}>
             {results.map((item)=>(
                 <Row>
                     <Col md={8} xs={24}>
                        <p style={{paddingRight: "1rem"}}>{item.Name}</p>
                     </Col>
                     <Col md={8} xs={24}>
                        <p>Балл: {item.Result.Int32 ? item.Result.Int32 : "Результатов нет"}</p>
                     </Col>
                     <Col md={8} xs={24}>
                        <p>Статус: {item.Status.String ? item.Status.String : "Результатов нет"}</p>
                     </Col>
                     <Divider/>
                 </Row>                                
            ))}
            {!results.length && "Вы ещё не зарегистрированы ни на одно мероприятие"}
        </Card>
    )
}