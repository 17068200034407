import './App.css';
import { store } from './store/store';

import { useSelector } from 'react-redux';
import { Main } from './components/Main';
import { Auth } from './components/Auth';
import { dispatchLogin, getToken } from './api/api';

function App() {
  const token = getToken()
  if(token){
    console.log(token)
    dispatchLogin({"token":token})
  }
  const authorized = useSelector(()=>store.getState().auth);
  return (authorized ? <Main/> : <Auth/>);
}

export default App;
