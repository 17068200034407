import { Button, Input, Form, notification, Select, DatePicker, Row, Col } from "antd";
import { useState, useEffect } from "react"
import { dispatchLogin, register } from "../api/api";
import locale from 'antd/lib/date-picker/locale/ru_RU'
import { getDocTypes, ping } from "../api/api";
import logo from "../logo.svg"
const confirmRegistration = (props, form) => {    
    form.validateFields().then(
        result=>{
            register(result)
            .then(
                result=>{
                    if(result === "ok"){
                        props.setReg(false)
                        notification.open({
                            message: 'Регистрация завершена',
                            description: 'На вашу почту выслан пароль для входа.',        
                        });
                    }
                    else {
                        notification.error({
                            message: 'Ошибка',
                            description: result,        
                        });
                    }                    
                }
            )
        },
        error=>{
            notification.error({
                message: 'Ошибка заполнения формы'     
            });
        }
    )
}

function LoginForm(props){
    const [form] = Form.useForm()
    return (
        <Form form={form} wrapperCol={{span: 24}} size="large">
            <h1>Вход</h1>
            <Form.Item name="email">
                <Input placeholder="Email"/>
            </Form.Item>
            <Form.Item name="password">
                <Input.Password placeholder="Пароль"/>
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={() => dispatchLogin(form.getFieldsValue(['email', 'password']))}>Войти</Button>   
                <Button type="dashed" style={{float: "right"}} onClick={()=>props.setReg(true)}>Регистрация</Button>             
            </Form.Item>
        </Form>
    )
}

function RegForm(props) {
    const [form] = Form.useForm()
    const [documentTypes, setDocumentTypes]     = useState([{}])
    const [docSerRule, setDocSerRule]           = useState({required: true, pattern: "^[0-9]{4}$"})
    const [docNumRule, setDocNumRule]           = useState({required: true, pattern: "^[0-9]{6}$"})
    const [docUnitRule, setDocUnitRule]         = useState({required: true})
    const [docUnitCodeRule, setDocUnitCodeRule] = useState({required: true, pattern: "^[0-9]{3}-[0-9]{3}$"})

    const validateMessages = {
        required: "Заполните это поле",
        types: {
            email: "Некорректный адрес",
        },        
        pattern: {
            mismatch: "Поле не соответствует шаблону",
          },
    };
    const dateFormat = "DD/MM/yyyy"

    const docChange = (selectValue) => {
        const applyRules = (rules) => {
            setDocSerRule(rules[0])
            setDocNumRule(rules[1])
            setDocUnitRule(rules[2])
            setDocUnitCodeRule(rules[3])
        } 
        switch (selectValue){
            //Паспорт РФ 
            case 1:
                applyRules([
                    {required: true, pattern: "^[0-9]{4}$", message: "Серия должна содержать четыре цифры"},
                    {required: true, pattern: "^[0-9]{6}$", message: "Номер должен содержать шесть цифр"},
                    {required: true},
                    {required: true, pattern: "^[0-9]{3}-[0-9]{3}$", message: "Пример: 111-222"},
                ])
                break
            //Иностранный паспорт
            case 2:
                applyRules([
                    {required: false},
                    {required: false},
                    {required: false},
                    {required: false},
                ])
                break
            //Заграничный паспорт
            case 3:
                applyRules([
                    {required: true, pattern: "^[0-9]{2}$", message: "Серия должна содержать две цифры"},
                    {required: true, pattern: "^[0-9]{6}$", message: "Номер должен содержать шесть цифр"},
                    {required: false},
                    {required: false},
                ])
                break
            //Свидетельство о рождении
            case 4:
                applyRules([
                    {required: true, pattern: "^[IVXLCDM]+-[a-zA-Z]+", message: "Пример заполнения: III-АГ"},
                    {required: true, pattern: "^[0-9]{6}$", message: "Номер должен содержать шесть цифр"},
                    {required: true},
                    {required: false},
                ])
                break
        }
    }

    useEffect(()=>{getDocTypes().then(result=>setDocumentTypes(result));docChange(1)}, [])
    
    return (
        <Form style={props.style} form={form} size="large" labelCol={{span: 10}} wrapperCol={{span: 14}} initialValues={{documentType: 1, schoolClass: 11}} validateMessages={validateMessages}>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item label="Фамилия" name="lastName" rules={[{required: true}]}>
                        <Input placeholder="Петров"/>
                    </Form.Item>
                    <Form.Item label="Имя" name="firstName" rules={[{required: true}]}>
                        <Input placeholder="Петр"/>
                    </Form.Item>
                    <Form.Item label="Отчество" name="fatherName" rules={[{required: true}]}>
                        <Input placeholder="Петрович"/>
                    </Form.Item>
                    <Form.Item label="Телефон" name="phone" rules={[{required: true, pattern: "([0-9]){10}"}]}>
                        <Input placeholder="9991112233"/>
                    </Form.Item>
                    <Form.Item label="Email" name="mail" rules={[{required: true, type: "email"}]}>
                        <Input placeholder="petrov@mail.ru"/>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Документ" name="documentType" rules={[{required: true}]}>
                    <Select onChange={(val)=>docChange(val)}>
                    {documentTypes.map(item => (
                        <Select.Option value={item.Id}>
                            {item.Name}
                        </Select.Option>
                    ))}
                    </Select>
                    </Form.Item> 
                    <Form.Item label="Серия" name="documentSeria" rules={[docSerRule]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Номер" name="documentNumber" rules={[docNumRule]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Кем выдан" name="documentUnit" rules={[docUnitRule]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Дата выдачи" name="documentGiveDate" rules={[{required: true}]}>
                        <DatePicker locale={locale} format={dateFormat}/>
                    </Form.Item>
                    <Form.Item label="Код подразделения" name="documentUnitCode" rules={[docUnitCodeRule]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Дата рождения" name="birthDate" rules={[{required: true}]}>
                        <DatePicker locale={locale} format={dateFormat}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} md={12}>
                    <Form.Item label="Учебное заведение" name="school" rules={[{required: true}]}>
                        <Input placeholder="Школа №1"/>
                    </Form.Item>
                    <Form.Item label="Населенный пункт" name="schoolPlace" rules={[{required: true}]}>
                        <Input placeholder="Нижний Новгород"/>
                    </Form.Item>
                    <Form.Item label="Класс" name="schoolClass" rules={[{required: true}]}>
                        <Select>
                            {[11, 10, 9, 8, 7, 6].map(item=>(
                                <Select.Option value={item}>
                                    {item}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item wrapperCol={{span: 24}}>
                <Button onClick={()=>props.setReg(false)}>Назад</Button>
                <Button type='primary' htmlType="submit" style={{float: 'right'}} onClick={() => confirmRegistration(props, form)}>Зарегистрироваться</Button>
            </Form.Item>
        </Form>
    )
}

export function Auth(){
    useEffect(()=>{
        ping().then(res=>{},()=>notification.open({
            message: 'Сервер недоступен, попробуйте позже'      
        }))
    }, [])

    const [reg, setReg] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    let isMobile = (width <= 768);

    return(
        <div>
            {!reg &&
            
            
            <div style={{position: "absolute", width: "100%", backgroundColor: "#1890ff"}}>
                {!isMobile &&
                    <div style={{display: 'inline-block', color: "white"}}>
                        <img src={logo} width={64} style={{filter: "invert(100%)", marginTop: "-50px", marginRight: "20px", marginLeft: "10px"}}/>
                    </div>
                }                
                <div style={{display: "inline-block", margin: "5px"}}>
                    <h1 style={{color: "white", fontWeight: "bold"}}>НГТУ им. Р. Е. Алексеева</h1>
                    <p style={{color: "#ddd"}}>Электронная регистрация на олимпиады</p>        
                </div>        
            </div>
            }
            <div className="regWrapper">
                <div id="sus" style={{}}>                
                {
                    reg ? 
                    <RegForm setReg={setReg} style={!isMobile? {width: "750px"}: {}}/>
                    :
                    <LoginForm setReg={setReg}/>
                }
                </div>
            </div> 
        </div>
              
    )
}
