import { Button, Col, Row, Card, Divider, notification } from "antd";
import { useEffect, useState } from "react"
import { genPDF, getOlymp, olympReg } from "../api/api"

const confirmOlympReg = (id) => {   
    olympReg(id)
    .then(
        response=>{
            if(response==="ok"){
                notification.open({
                    message: 'Вы зарегистрированы на мероприятие', 
                    description: 'Не забудьте распечатать бланк участника'    
                });
            }
            else {
                notification.error({
                    message: 'Ошибка',
                    description: response,        
                });
            }
        }
    )
}

const  printCard = (id) => {
    genPDF(id)
    .then(blob=>{
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'file.pdf';
        document.body.append(link);
        link.click();
        link.remove();
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    })
}

function RegButton(props){
    let [active, setActive] = useState(props.active)
    return (
        <span>
            <Button onClick={()=>{confirmOlympReg(props.dataId); setActive(false)}} disabled={!active}>
                {active ? "Принять участие":"Вы уже участвуйте"}
            </Button>
            {!active&& <Button style={{marginLeft: "1rem"}} onClick={()=>printCard(props.dataId)}>Скачать бланк участника</Button>}
        </span>
    )
}

export function Olymp(){
    let [olympList, setOlympList] = useState([])
    useEffect(()=>getOlymp().then(result => {setOlympList(result)}), [])
    return(
        <Card style={{minHeight: "100%"}}>
             {olympList.map((item)=>(
                 <Row>
                     <Col md={12} xs={24}>
                        <p style={{paddingRight: "1rem"}}>{item.Name}</p>
                     </Col>
                     <Col>
                        <RegButton dataId={item.Id} active={!item.Registered}></RegButton>
                     </Col>
                     <Divider/>
                 </Row>                                
            ))}
        </Card>
                             
    )
}